import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Euristico<br/><span style={{fontSize: 14}}>
          Something beautiful is in making.. See you soon.
        </span></p>
        
      </header>
    </div>
  );
}

export default App;
